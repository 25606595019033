import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { AlertsBasic, AlertsWithLink, AlertsWithButton, AlertsDismissible } from "../../examples/components/Alerts";
export const query = graphql`
  query AlertQuery {
    Alert: componentMetadata(displayName: { eq: "Alert" }) {
      ...ComponentApi_metadata
    }
    AlertLink: componentMetadata(displayName: { eq: "AlertLink" }) {
      ...ComponentApi_metadata
    }
    AlertHeading: componentMetadata(displayName: { eq: "AlertHeading" }) {
      ...ComponentApi_metadata
    }
    AlertLink: componentMetadata(displayName: { eq: "AlertLink" }) {
      ...ComponentApi_metadata
    }
    AlertDismissible: componentMetadata(
      displayName: { eq: "AlertDismissible" }
    ) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Alerts are available for any length of text, as well as an optional dismiss
  button. For proper styling, use one of the eight variants.
    </Overview>
    <CodeBlock title="Basic Alerts" code={AlertsBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Alerts with Links" code={AlertsWithLink} mdxType="CodeBlock">
  For links, use the <code>&lt;Alert.Link&gt;</code> component to provide
  matching colored links within any alert.
    </CodeBlock>
    <CodeBlock title="Alerts with Buttons" code={AlertsWithButton} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Dismissible Alerts" code={AlertsDismissible} mdxType="CodeBlock">
  Use the custom react component <code>AlertDismissible</code>.
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="alerts-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Alert} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AlertHeading} exportedBy={props.data.Alert} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AlertLink} exportedBy={props.data.Alert} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AlertDismissible} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      